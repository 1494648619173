
.App-link {
  color: #6799b6;
  display: block;
  text-decoration:underline;

}

.App-link:hover {
  text-decoration:none;

  color: #2e536a;

}

.social-link {
  color: darkgray;

  display: block;
  text-decoration: none;
}

.social-link:hover {
  color: rgb(70, 70, 70)
  

}
.main-container {
  margin: 0px 50px;
  background-color: #ffffff;   /* #282c34 */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-color: white;
}


.top-box {
  text-align: left;
  font-size: calc(4vmin);
  letter-spacing: 1px; /* Adjust the letter-spacing value as needed */
}
.top-box h1 {
  margin-bottom:1vh;
  padding-top: 0px;
  margin-top:0px;
  


}

.top-box p {
  padding: 0px;
  margin:0;
  margin-bottom: 2.5vh;
}

.link-box {
  justify-content: space-between;
  display: flex;
  font-size: calc(3vmin);
  letter-spacing: 2px; /* Adjust the letter-spacing value as needed */
}
.link-box-1 {
  text-align: left;
}
.link-box-2 {
  text-align: right;
}

@media only screen and (max-width: 600px) {
  .link-box {
    display: block;

  }

  .link-box-2 {
    text-align: left;
  }
  
}



.link-box > div {
  margin-right: 10px; /*Adjust the margin as needed for the desired spacing*/
}

.link-box > div:last-child {
  margin-right: 0; /* Remove margin from the last div to avoid extra spacing */
}


.bottom-box {
  height: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Align items to the bottom */

}
.social-box {
  display: flex;
  justify-content: space-between;
  font-size: calc(2.5vmin);
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the grid items horizontally */
  align-items: center; /* Center the grid items vertically, if needed */
  width: 100%; /* Ensure the grid container takes up the full width */
  max-width: 1300px; /* Set a maximum width for the grid container */
  margin: 0 auto; /* Center the grid container within its parent */
}

.grid-item-container {
  margin: 10px;
  text-align: center;
  cursor: pointer; /* Add cursor pointer to indicate clickability */
}

.grid-item {
  max-width: 400px;
  height: auto;
}

.image-label {
  margin-top: 5px;
  font-size: 14px;
  color: grey;
}

.photographing2020 {
  color: grey;
  text-align: left;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 700px;
  text-align: center;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-image {
  max-width: 100%;
  height: auto;
}

.modal-label {
  margin-top: 10px;
  font-size: 18px;
  color: #333;
}

li a {
  color: #6799b6
}